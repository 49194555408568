import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { SliceZone } from '@prismicio/react'

import Layout from '../components/Layout'
import { Seo } from '../components/Seo'
import { components } from '../slices/brands/forty-nine-north'

//components
import FortyNineNorthHero from '../components/brands/forty-nine-north/FortyNineNorthHero'
import FortyNineNorthFindYourBottleSection from '../components/brands/forty-nine-north/FindYourBottleSection'

//preview
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const BrandFortyNineNorth = ({ data }) => {
    const [sliceData, setSliceData] = useState([])
    /* REFORMAT DATA FOR SLICEZONE DATA */
    const pageData = data.prismicSingleBrandPage.data

    useEffect(() => {
      /* ADD MODULES AND SECTIONS TO SLICEDATA */
      const newSliceData = data.prismicSingleBrandPage.data.body

      // Push products to sliceData array
      var products = pageData.body1
      var productsSliceData = {
          slice_type: "products",
          primary: {
              section_title: pageData.products_section_title.text,
              products: products
          }
      }

      var checkProductsDuplicateData = newSliceData.map((item) => {
        if(item.slice_type === "products") return true;
        return false;
      })
      if(!checkProductsDuplicateData.includes(true)) newSliceData.splice(0, 0, productsSliceData)
      //Eof push products to sliceData array

      //Push product details to sliceData array
      var productDetailsSliceData = {
        slice_type: "product_details",
        primary: {
          products: products
        }
      }
      var checkProductDetailsDuplicateData = newSliceData.map((item) => {
        if(item.slice_type === "product_details") return true;
        return false;
      })
      if(!checkProductDetailsDuplicateData.includes(true)) newSliceData.splice(2,0, productDetailsSliceData)
      //Eof push product details to sliceData array

      setSliceData(newSliceData)

      //Scroll to the top on new page
      document.documentElement.style.scrollBehavior = 'auto'
      window.scrollTo(0,0)
      document.documentElement.style.scrollBehavior = 'smooth'
    }, [data.prismicSingleBrandPage.data.body, pageData.body1, pageData.products_section_title.text])

    return(
        <Layout currentPage="forty-nine-north" customPage={true}>
            <Seo
              title={(pageData.meta_title)? pageData.meta_title.text : null}
              description={(pageData.meta_description)? pageData.meta_description.text : null}
            />
            <FortyNineNorthHero
              title={pageData.banner_title.text}
              description={pageData.banner_description.text}
              logoUrl={pageData.brand_logo.url}
              logoAlt={pageData.brand_logo.alt}
            />
            <SliceZone slices={sliceData} components={components} />
            <FortyNineNorthFindYourBottleSection />
        </Layout>
    )
}

export default withPrismicPreview(BrandFortyNineNorth)

export const query = graphql`
query BrandForthNineNorthQuery {
    prismicSingleBrandPage(uid: {eq: "forty-nine-north"}) {
        _previewable
        data {
            meta_title {
                text
            }
            meta_description{
                text
            }
            banner_title {
                text
            }
            banner_description {
                text
                html
            }
            brand_logo {
                url
                alt
            }
            products_section_title {
                text
            },
            body {
                ... on PrismicSliceType {
                    slice_type
                }
                ... on PrismicSingleBrandPageDataBodyWhatOthersSay {
                    id
                    items {
                      description {
                        text
                        html
                      }
                      instagram_link {
                        raw
                        size
                        slug
                        tags
                        target
                        type
                        uid
                        url
                        link_type
                        lang
                        isBroken
                        id
                      }
                      name {
                        text
                      }
                      subtitle {
                        text
                      }
                    }
                    primary {
                      section_title {
                        text
                      }
                    }
                }
                ...on PrismicSingleBrandPageDataBodyOurWineMakersNote {
                    id
                    slice_type
                    primary {
                        section_button_text {
                            text
                        }
                        section_button_link {
                          raw
                          size
                          slug
                          tags
                          target
                          type
                          uid
                          url
                          link_type
                          lang
                          isBroken
                          id
                        }
                        section_title {
                            text
                        }
                        section_description {
                            text
                            html
                        }
                    }
                }
                ...on PrismicSingleBrandPageDataBodyInstagramOrGallery {
                  id
                  items {
                    gallery_photo {
                      alt
                      url
                    }
                  }
                  primary {
                    display_instagram
                  }
                  slice_type
                }
            }
            body1 {
                ... on PrismicSingleBrandPageDataBody1Product {
                    id
                    items {
                      alcohol_content
                      appellation {
                        text
                      }
                      blend {
                        text
                      }
                      bottle_size
                      product_region
                      tasting_note {
                        text
                        html
                      }
                      what_it_tastes_like_dry_sweet
                      what_it_tastes_like_light_bold
                      what_it_tastes_like_soft_acidic
                    }
                    primary {
                      product_description {
                        text
                      }
                      product_image {
                        url
                        alt
                      }
                      product_list_image{
                        url
                        alt
                      }
                      product_image_mobile{
                        url
                        alt
                      }
                      product_link_text {
                        text
                      }
                      product_name {
                        text
                      }
                      product_pdf_link {
                        raw
                        size
                        slug
                        tags
                        target
                        type
                        uid
                        url
                        link_type
                        lang
                        isBroken
                        id
                      }
                      where_to_buy_link {
                        raw
                        size
                        slug
                        tags
                        target
                        type
                        uid
                        url
                        link_type
                        lang
                        isBroken
                        id
                      }
                      product_details_content_color
                      product_details_background_color
                    }
                    slice_type
                  }
            }
        }
    }
}
`