import React from 'react'
import { useEffect, useState, useRef } from 'react'

import {Link} from 'gatsby'

//assets
import findYourBottleBg from '../../../assets/images/brands/forty-nine-north/find-your-bottle-bg.png'
import findYourBottleBgMobile from '../../../assets/images/brands/forty-nine-north/find-your-bottle-bg-mobile.png'

import { isElementInViewport } from '../../functions'

const FindYourBottleSection = () => {
    const [isMobile, setIsMobile] = useState(false)
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)

    //choose the screen size
    const handleResize = () => {
        if(window.innerWidth < 992){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
    }

    //create an event listener
    useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)

        if(isElementInViewport(ref.current)){
            setIsInView(isElementInViewport(ref.current))
        }
        window.addEventListener('scroll',() => {
            if(isElementInViewport(ref.current)){
                setIsInView(true)
            }
        })
    }, [])

    return (
        <section
            className={(isInView)? 'find-your-bottle d-flex justify-content-center align-items-center in-view' : 'find-your-bottle d-flex justify-content-center align-items-center'} 
            ref={ref}
            style={{backgroundImage: `url(${(isMobile)? findYourBottleBgMobile : findYourBottleBg})`}}>
            <div className='container text-center slide-up'>
                <h3 className="text-uppercase">find your bottle of forty nine north</h3>
                <Link to="/find-your-wine" title='Go to Wine Locator'>
                    <button className='btn btn-primary-2 mb-0'>Go to Wine Locator</button>
                </Link>
            </div>
        </section>
    )
}

export default FindYourBottleSection